import {
  MEASUREMENTS_LATEST_FETCH_REQUEST,
  MEASUREMENTS_LATEST_FETCH_RESPONSE,
  MEASUREMENTS_LATEST_FETCH_FAILURE,
  MeasurementState,
  MeasurementActionTypes,
} from '../types/Measurement';

const initialState: MeasurementState = {
  measurements: [],
  latestMeasurements: null,
  isFetchingMeasurements: false,
  fetchingMeasurementsFailed: false,
};

const measurementReducer = (
  state = initialState,
  action: MeasurementActionTypes,
): MeasurementState => {
  switch (action.type) {
    case MEASUREMENTS_LATEST_FETCH_REQUEST:
      return {
        ...state,
        isFetchingMeasurements: true,
        fetchingMeasurementsFailed: false,
      };
    case MEASUREMENTS_LATEST_FETCH_RESPONSE:
      return {
        ...state,
        latestMeasurements: action.payload || [],
        isFetchingMeasurements: false,
        fetchingMeasurementsFailed: false,
      };
    case MEASUREMENTS_LATEST_FETCH_FAILURE:
      return {
        ...state,
        latestMeasurements: null,
        isFetchingMeasurements: false,
        fetchingMeasurementsFailed: true,
      };
    default:
      return state;
  }
};

export default measurementReducer;
