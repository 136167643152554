import React from 'react'
import SuhsteyView from "../components/View";
import AlertCard from "../components/AlertCard";

const Alerts = () => {
    return (
        <SuhsteyView>
            <div className="alert-section mt-0">
                <div className="alert-top">
                    <span className="sub-title">ALERTS</span>
                    <span className="priority">
              P1 = HIGH PRIORITY | P2 = MEDIUM PRIORITY
            </span>
                </div>

                <hr className="horizontal-line" />
                <div className="row g-5">
                    <AlertCard
                        propertyNumber="P2"
                        unit="Temperature"
                        status=""
                        image="thermometer"
                        roomNumber="Room 1"
                        warning="High Temperature"
                        color="#826D5E"
                        backgroundColor="#F9F0C9"
                    />
                    <AlertCard
                        propertyNumber="P1"
                        unit="Ambient Light"
                        status=""
                        image="light"
                        roomNumber="Room 1"
                        warning="Low Room Lighting"
                        color="#F9F0C9"
                        backgroundColor="#826D5E"
                    />
                </div>
            </div>
        </SuhsteyView>)
};

export default Alerts;
