import axios from 'axios';
import c from '../helpers/constants';

export function fetchLastMeasurementForSensor(sensorId: number) {
  return (
    axios({
      url: `${c.API_URL}/measurement/sensor/${sensorId}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: any) => response?.data)
      // .then(() => PropertyInfoMockJson)
      .catch((error: any) => {
        console.error(error);
      })
  );
}

export function fetchLastMeasurements() {
    return (
        axios({
            url: `${c.API_URL}/measurement/latest/all`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response: any) => response?.data)
            // .then(() => PropertyInfoMockJson)
            .catch((error: any) => {
                console.error(error);
            })
    );
}
