import React from 'react'
import SuhsteyView from "../components/View";
import { LightningChargeFill } from "react-bootstrap-icons";

const FAQ = () => {
    const facts = ['Although accounting for only 5 percent of the world\'s population, Americans consume 26 percent of the world\'s energy.',
        'America uses about 15 times more energy per person than the typical developing country.',
        'A heavy coat of dust on a light bulb can block up to half of the light.',
        'When you turn on an incandescent light bulb, only 10% of the electricity used is turned into light. The other 90% is wasted as heat.',
        'A compact fluorescent light bulb uses 75 percent less energy than a regular bulb and it can last up to four years.',
        'A crack as small as 1/16th of an inch around a window frame can let in as much cold air as leaving the window open three inches.',
        'Some new refrigerators are so energy-smart they use less electricity than a light bulb.',
        'Every time you open the refrigerator door, up to 30 percent of the cold air can escape.',
        'Every year, more than $13 billion worth of energy leaks from houses through small holes and cracks. That\'s more than $150 per family.',
        'Office buildings use approximately 19 percent of all energy consumed in the United States.',
        'Heating, ventilating and air conditioning systems account for 40-60 percent of total energy use in the commercial sector.']
  return (
      <SuhsteyView>
          <ul className="list-group list-group-flush">
          {facts.map(f =>
              <li className="list-group-item list-group-item-success">
                  <LightningChargeFill fill="#4d5945" />{' '}{f}
              </li>
          )}
          </ul>
      </SuhsteyView>)
};

export default FAQ;
