import React from 'react';
import Navigation from '../Navigation';
import './View.scss';
import {NavLink} from 'react-router-dom';
import CONSTANTS from '../../helpers/constants';
import logoPrimary from '../../assets/svgs/logo_primary.svg';

const View = (props: any) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-3 d-md-none d-none d-sm-none d-lg-block navigation-block">
                    <Navigation/>
                </div>
                <div className="col-lg-9 col-md-12 col-sm-12 content-block">
                    <div className="row p-4">
                        <div className="col-12 master-logo">
                            <NavLink exact={true} to={CONSTANTS.APP_ROUTES.DASHBOARD}>
                                <img alt="suhstey-logo" src={logoPrimary}/>
                            </NavLink>
                        </div>
                    </div>
                    {props.children}
                </div>
            </div>
            <div className="d-lg-none">
                <Navigation/>
            </div>
        </div>
    );
};

export default View;
