import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import {
    MEASUREMENTS_LATEST_FETCH_FAILURE,
    MEASUREMENTS_LATEST_FETCH_REQUEST,
    MEASUREMENTS_LATEST_FETCH_RESPONSE,
    Measurement,
    MeasurementActionTypes,
} from '../types/Measurement';

import {fetchLastMeasurements} from '../api/MeasurementApi';
import {RootState} from '../store/configureStore';

export function measurementsLatestGetRequest(): MeasurementActionTypes {
    return {
        type: MEASUREMENTS_LATEST_FETCH_REQUEST,
        payload: null,
    };
}

export function measurementsLatestGetResponse(
    measurementsLatest: Measurement[],
): MeasurementActionTypes {
    return {
        type: MEASUREMENTS_LATEST_FETCH_RESPONSE,
        payload: measurementsLatest,
    };
}

export function measurementsLatestGetFailure(): MeasurementActionTypes {
    return {
        type: MEASUREMENTS_LATEST_FETCH_FAILURE,
        payload: null,
    };
}

export const measurementsLatestGet =
    (): ThunkAction<void, RootState, unknown, Action<string>> =>
        async (dispatch) => {
            dispatch(measurementsLatestGetRequest());
            const asyncResp: any = await fetchLastMeasurements();
            if (asyncResp.success) {
                dispatch(measurementsLatestGetResponse(asyncResp.data));
            } else {
                dispatch(measurementsLatestGetFailure());
            }
        };
