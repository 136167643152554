import React from 'react'
import SuhsteyView from "../components/View";

const Rewards = () => {
  return (
    <SuhsteyView>
      This is Rewards Page
    </SuhsteyView>
  )
};

export default Rewards;
