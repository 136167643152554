import "bootstrap/dist/css/bootstrap.min.css";
import LogoSecondary from "../../assets/svgs/logo-secondary.svg";
import "./WelcomeCard.scss";

const WelcomeCard = (props: { name: string; description: string }) => {
    return (
        <div className="col-lg-10 col-md-10 offset-md-1">
            <div className="welcome-message-box">
                <h4 className="welcome-header">
                    Welcome back, <b>{props.name}</b>
                </h4>
                <p className="welcome-description">{props.description}</p>
                <div className="boxed-logo">
                    <img src={LogoSecondary} className="logo" alt="logo"/>
                </div>
            </div>
        </div>
    );
};

export default WelcomeCard;
