import "./AlertCard.scss";
import TemperatureIcon from "../../assets/svgs/temperature-icon.svg";
import TimeIcon from "../../assets/svgs/time-icon.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import ElectricityIcon from "../../assets/svgs/elec1.svg";
import CleanAirIcon from "../../assets/svgs/cleanAir.svg";
import Thermometer from "../../assets/svgs/temperature-icon.svg";
import WaterIcon from "../../assets/svgs/waterIcon.svg";
import MotionIcon from "../../assets/svgs/motion.svg";
import HumidityIcon from "../../assets/svgs/humid.svg";
import BarometerIcon from "../../assets/svgs/pressure.svg";
import AmbientLightIcon from "../../assets/svgs/ambient.svg";

const AlertCard = (props: {
    propertyNumber: string;
    unit: string;
    status: string;
    image: "power" | "air" | "water" | "thermometer" | "light" | "humidity" | "motion" | "pressure";
    roomNumber: string;
    warning: string;
    color: string;
    backgroundColor: string;
}) => {

    const renderIcon = () => {
        switch (props.image) {
            case "power":
                return ElectricityIcon;
            case "air":
                return CleanAirIcon;
            case "thermometer":
                return Thermometer;
            case "water":
                return WaterIcon;
            case "motion":
                return MotionIcon;
            case "humidity":
                return HumidityIcon;
            case "pressure":
                return BarometerIcon;
            case "light":
                return AmbientLightIcon;
            default:
                return "Icon";
        }
    };
    return (
        <div className="col-lg-10 col-md-10 offset-md-1">
            <div className="alert-card">
                <div className="side-bar" style={{color: props.color, backgroundColor: props.backgroundColor}}>
                    <h1>{props.propertyNumber}</h1>
                </div>
                <div className="alert-card_data">
                    <div className="alert-card_data_left">
                        <div className="alert-card_data_left-top">
                            <h5 className="title">{props.unit}</h5>
                            <div className="description">{props.status}</div>
                        </div>
                        <div className="alert-card_data_left-bottom">
                            <div className="room-number">{props.roomNumber}</div>
                        </div>
                    </div>
                    <div className="alert-card_data_right">
                        <div className="alert-card_data_right-top">
                            <img
                                src={renderIcon()}
                                className="temperature-icon"
                                alt="Thermometer"
                            />
                            <div className="warning">
                                <div className="warning-text">Warning:</div>
                                <div className="temperature-alert">{props.warning}</div>
                            </div>
                        </div>
                        <div className="alert-card_data_right-bottom">
                            <img src={TimeIcon} className="time-icon" alt="Clock"/>
                            <div className="time-created">
                                <div className="created-text">Created:</div>
                                <div className="date-time">
                                    <div className="date">07/09/21</div>
                                    <div className="time">7:15pm</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlertCard;
