const API_URL = 'https://suhstey-api.mrhighline.com/api';

// const API_URL = 'http://127.0.0.1:5050/api';

const APP_ROUTES = {
  DASHBOARD: '/',
  PERFORMANCE: '/performance',
  ALERTS: '/alerts',
  SAVINGS: '/savings',
  // REWARDS: '/rewards',
  TIPS: '/tips',
};

const CONSTANTS = {
  API_URL,
  APP_ROUTES,
};

export default CONSTANTS;
