export const MEASUREMENTS_LATEST_FETCH_REQUEST = 'MEASUREMENTS_LATEST_FETCH_REQUEST';
export const MEASUREMENTS_LATEST_FETCH_RESPONSE = 'MEASUREMENTS_LATEST_FETCH_RESPONSE';
export const MEASUREMENTS_LATEST_FETCH_FAILURE = 'MEASUREMENTS_LATEST_FETCH_FAILURE';

export interface Sensor {
  name: string;
  description: string;
  id: number;
  created_at: Date;
  updated_at: Date;
}

export interface Measurement {
  id: number;
  sensor_id: number;
  data: any;
  min: number,
  max: number,
  avg: number,
  sensor: Sensor;
  created_at: Date;
}

export interface MeasurementState {
  measurements: Measurement[];
  latestMeasurements: Measurement[] | null;
  isFetchingMeasurements: boolean;
  fetchingMeasurementsFailed: boolean;
}

interface MeasurementsLatestGetRequestAction {
  type: typeof MEASUREMENTS_LATEST_FETCH_REQUEST;
  payload: null;
}

interface MeasurementsLatestGetResponseAction {
  type: typeof MEASUREMENTS_LATEST_FETCH_RESPONSE;
  payload: Measurement[];
}

interface MeasurementsLatestGetFailureAction {
  type: typeof MEASUREMENTS_LATEST_FETCH_FAILURE;
  payload: null;
}

export type MeasurementActionTypes =
  | MeasurementsLatestGetRequestAction
  | MeasurementsLatestGetResponseAction
  | MeasurementsLatestGetFailureAction;
