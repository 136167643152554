import "./PerformanceBar.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    buildStyles,
    CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import ElectricityIcon from "../../assets/svgs/elec1.svg";
import WaterIcon from "../../assets/svgs/waterIcon.svg";
import CleanAirIcon from "../../assets/svgs/cleanAir.svg";
import Thermometer from "../../assets/svgs/temperature-icon.svg";
import BarometerIcon from "../../assets/svgs/pressure.svg";
import HumidityIcon from "../../assets/svgs/humid.svg";
import AmbientLightIcon from "../../assets/svgs/ambient.svg";
import MotionIcon from "../../assets/svgs/motion.svg";

const PerformanceBar = (props: {
    minValue: number;
    maxValue: number;
    avgValue: number;
    title: string;
    value: number;
    image: "power" | "air" | "water" | "thermometer" | "light" | "humidity" | "motion" | "pressure";
    remark: string;
    unitValue: string;
}) => {
    const renderIcon = () => {
        switch (props.image) {
            case "power":
                return ElectricityIcon;
            case "air":
                return CleanAirIcon;
            case "thermometer":
                return Thermometer;
            case "water":
                return WaterIcon;
            case "motion":
                return MotionIcon;
            case "humidity":
                return HumidityIcon;
            case "pressure":
                return BarometerIcon;
            case "light":
                return AmbientLightIcon;
            default:
                return "Icon";
        }
    };

    const round = (num: number) => Math.round(num * 100);
    const renderUnits= () => {
        switch (props.image) {
            case "power":
                return <span>Consuming {props.unitValue} kWh</span>;
            case "air":
                return <span>Quality at {round(1 -(parseInt(props.unitValue, 10) / props.avgValue))} %</span>;
            case "thermometer":
                return <span>Room temp at {props.unitValue} °C</span>;
            case "water":
                return <span>Current water consumption at {props.unitValue} m3</span>;
            case "motion":
                return <span>{props.unitValue ? 'Motion Detected' : 'No Motion Detected'}</span>;
            case "humidity":
                return <span>Current level at {round((parseInt(props.unitValue, 10) / props.maxValue))} %</span>;
            case "pressure":
                return <span>{parseInt(props.unitValue, 10)} hPa</span>;
            case "light":
                return <span>Luminescence at {round((parseInt(props.unitValue, 10) / props.maxValue))} %</span>;
            default:
                return "Icon";
        }
    };

    // let finalRemark = props.remark;
    //
    // const spot = props.value / props.maxValue;
    //
    // if (spot >= 0.1 && spot <= 0.25) {
    //     finalRemark = 'Poor';
    // } else if (spot > 0.25 && spot <= 0.5) {
    //     finalRemark = 'Average';
    // } else if (spot > 0.5 && spot <= 0.75) {
    //     finalRemark = 'Fair';
    // } else if (spot > 0.75) {
    //     finalRemark = 'High';
    // } else if (spot < 0.1) {
    //     finalRemark = 'Low';
    // }

    return (
        <div className="col-lg-3 col-md-3 col-6 performance-bar-wrapper justify-content-around">
            <div className="performance-bar">
                <h2 className="performance-bar__title">{props.title}</h2>
                <div className="performance-bar__box">

                    <CircularProgressbarWithChildren
                        value={props.image === 'air' ? props.avgValue - props.value: props.value}
                        maxValue={props.image === 'air' ? props.avgValue : props.maxValue}
                        strokeWidth={12}
                        background
                        counterClockwise={false}
                        backgroundPadding={0}
                        styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: "#faefdf",
                            trailColor: "#ba9d85",
                            backgroundColor: "#fffdfa",
                            pathTransitionDuration: 2.5,
                        })}
                    >
                        <div>
                            <img className="performance-bar__box__icon" src={renderIcon()} alt={props.image}/>
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
                <div className="performance-bar__footer">
                    {/*<h2 className="performance-bar_remark">{finalRemark}</h2>*/}
                    <p className="performance-bar__value">{renderUnits()}</p>
                </div>
            </div>
        </div>
    );
};

export default PerformanceBar;
