import React from 'react'
import SuhsteyView from '../components/View';
import GreenSavingsBar from "../components/GreenSavingsBar";

const Savings = () => {
    return (
        <SuhsteyView>
            <div className="">
                <span className="sub-title">SAVINGS</span>
                <hr className="horizontal-line" style={{ marginBottom: "2rem" }} />

                <div className="row g-5">
                    <GreenSavingsBar title="Electricity" value={75} totalSaved={25.6} />
                </div>
            </div>
        </SuhsteyView>
    )
};

export default Savings;
