import {createStore, applyMiddleware} from 'redux';
import thunk, {ThunkAction} from 'redux-thunk';
import {Action, combineReducers} from 'redux';
import measurementReducer from '../reducers/Measurement';

const rootReducer = combineReducers({
  measurement: measurementReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
