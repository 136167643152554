import {Switch, Route} from 'react-router-dom';
import Dashboard from '../../views/Dashboard';
import Alerts from '../../views/Alerts';
import Savings from '../../views/Savings';
import FAQ from '../../views/FAQ';
import Rewards from '../../views/Rewards';
import Performance from '../../views/Performance';


const Router = () => {
    return (
        <Switch>
            <Route exact path="/performance" component={Performance}/>
            <Route exact path="/alerts" component={Alerts}/>
            <Route exact path="/savings" component={Savings}/>
            <Route exact path="/rewards" component={Rewards}/>
            <Route exact path="/tips" component={FAQ}/>
            <Route exact path="/" component={Dashboard}/>
        </Switch>
    );
};

export default Router;
