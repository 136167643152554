import React, {useEffect} from 'react'
import SuhsteyView from "../components/View";
import PerformanceBar from "../components/PerformanceBar";
import {useDispatch, useSelector} from "react-redux";
import {measurementsLatestGet} from "../actions/Measurement";
import {getLatestMeasurements} from "../selectors/Measurement";

const Performance = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(measurementsLatestGet())
        const uniqueId = setInterval(() => {
            dispatch(measurementsLatestGet())
        }, 5000);

        return () => {
            clearInterval(uniqueId);
        }
    }, [dispatch]);


    const latestMeasurements = useSelector(getLatestMeasurements);
    return (
        <SuhsteyView>
            <div className="">
                <div className="performance-section__title">
                    <span className="sub-title">PERFORMANCE</span>
                </div>

                <hr className="horizontal-line" style={{marginBottom: "2rem"}}/>
                <div className="row g-0 justify-content-evenly">
                    {latestMeasurements?.map(m =>
                        <PerformanceBar
                            title={m.sensor.description}
                            minValue={m.min}
                            maxValue={m.max}
                            avgValue={m.avg}
                            value={m.data?.value}
                            //@ts-ignore
                            image={m.sensor.name}
                            remark="Fair"
                            unitValue={m?.data?.value}
                        />)}
                </div>

            </div>
        </SuhsteyView>)
};

export default Performance;
