import "./GreenSavingsBar.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-circular-progressbar/dist/styles.css";
import { ArrowRight } from "react-bootstrap-icons";
import Coins from "../../assets/svgs/coins.svg";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

let url = "";

const GreenSavingsBar = (props: {
  title: string;
  value: number;
  totalSaved: number;
}) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6 center-me">
      <div className="green-savings-card">
        <h3 className="green-savings-card_title">{props.title}</h3>
        <CircularProgressbarWithChildren
          value={props.value}
          strokeWidth={10}
          styles={buildStyles({
            strokeLinecap: "butt",
            pathColor: "#4d5945",
            trailColor: "#f6fff0",
          })}
        >
          <div
            style={{
              fontSize: 12,
              marginTop: -5,
              textAlign: "center",
              color: "#f6fff0",
              lineHeight: "1rem",
            }}
          >
            <h3 style={{ margin: 0 }}>{props.totalSaved} AED</h3>
            <p style={{ fontSize: "20px", marginBottom: 0 }}>Saved</p>
          </div>
        </CircularProgressbarWithChildren>
        <div className="green-savings-card_bottom-left">
          <img src={Coins} className="coins-icon" alt="Coins" />
          <span className="comment">GOOD JOB!</span>
        </div>
        <a href={url}>
          <div className="green-savings-card_bottom-right">
            <span className="details-cta">Details</span>
            <ArrowRight />
          </div>
        </a>
      </div>
    </div>
  );
};

export default GreenSavingsBar;
