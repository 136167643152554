import React from "react";
import { Sun } from "react-bootstrap-icons";
import "./LocationStatusCard.scss";

const LocationStatus = (props: { location: string, score: number }) => {
  return (
      <div className="col-lg-6 offset-md-3 col-md-6 col-sm-12">
        <div className="status-card">
          <div className="top">
            <div className="weather">
              <Sun className="weather_icon" />
              <span className="weather_description">Clear / 40°C / 7mph</span>
            </div>
            <div className="green-score">
              <h5 className="green-score_title">Green Score</h5>
              <div className="green-score_number">
                <span>{props.score}</span>
              </div>
            </div>
          </div>
          <div className="bottom">
            <span className="location-title">Location:</span>
            <h1 className="location">{props.location}</h1>
          </div>
        </div>
      </div>
  );
};

export default LocationStatus;
