import React, {useEffect, useState} from 'react';
import '../styles/Dashboard.scss';
import LocationStatusCard from '../components/LocationStatusCard';
import AlertCard from '../components/AlertCard';
import GreenSavingsBar from '../components/GreenSavingsBar';
import SuhsteyView from '../components/View';
import PerformanceBar from '../components/PerformanceBar';
import WelcomeCard from '../components/WelcomeCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useDispatch, useSelector} from 'react-redux';
import {getLatestMeasurements} from '../selectors/Measurement';
import {measurementsLatestGet} from '../actions/Measurement';


const Dashboard = () => {

    const dispatch = useDispatch();

    const [factIndex] = useState(Math.floor(Math.random() * 11));

    useEffect(() => {
        dispatch(measurementsLatestGet())
        const uniqueId = setInterval(() => {
            dispatch(measurementsLatestGet())
        }, 30000);

        return () => {
            clearInterval(uniqueId);
        }
    }, [dispatch]);


    const latestMeasurements = useSelector(getLatestMeasurements);
    const facts = ['Although accounting for only 5 percent of the world\'s population, Americans consume 26 percent of the world\'s energy.',
        'America uses about 15 times more energy per person than the typical developing country.',
        'A heavy coat of dust on a light bulb can block up to half of the light.',
        'When you turn on an incandescent light bulb, only 10% of the electricity used is turned into light. The other 90% is wasted as heat.',
        'A compact fluorescent light bulb uses 75 percent less energy than a regular bulb and it can last up to four years.',
        'A crack as small as 1/16th of an inch around a window frame can let in as much cold air as leaving the window open three inches.',
        'Some new refrigerators are so energy-smart they use less electricity than a light bulb.',
        'Every time you open the refrigerator door, up to 30 percent of the cold air can escape.',
        'Every year, more than $13 billion worth of energy leaks from houses through small holes and cracks. That\'s more than $150 per family.',
        'Office buildings use approximately 19 percent of all energy consumed in the United States.',
        'Heating, ventilating and air conditioning systems account for 40-60 percent of total energy use in the commercial sector.'];

    return (
        <SuhsteyView>

            <div className="row">
                <WelcomeCard
                    name="Citizen of Earth"
                    description={facts[factIndex]}
                />
            </div>

            <div className="location-section">
                <span className="sub-title">LOCATION</span>
                <hr className="horizontal-line"/>
                <div className="row">
                    <LocationStatusCard location="DUBAI" score={4.5}/>
                </div>
            </div>
            <div className="alert-section">
                <div className="alert-top">
                    <span className="sub-title">ALERTS</span>
                    <span className="priority">
              P1 = HIGH PRIORITY | P2 = MEDIUM PRIORITY
            </span>
                </div>

                <hr className="horizontal-line"/>
                <div className="row g-5">
                    <AlertCard
                        propertyNumber="P2"
                        unit="Temperature"
                        image="thermometer"
                        status=""
                        roomNumber="Room 1"
                        warning="High Temperature"
                        color="#826D5E"
                        backgroundColor="#F9F0C9"
                    />
                    <AlertCard
                        propertyNumber="P1"
                        unit="Ambient Light"
                        status=""
                        image="light"
                        roomNumber="Room 1"
                        warning="Low Room Lighting"
                        color="#F9F0C9"
                        backgroundColor="#826D5E"
                    />
                </div>
            </div>

            <div className="performance-section">
                <div className="performance-section__title">
                    <span className="sub-title">PERFORMANCE</span>
                </div>

                <hr className="horizontal-line"/>
                <div className="row g-0 justify-content-evenly">
                    {latestMeasurements?.map(m =>
                        <PerformanceBar
                            title={m.sensor.description}
                            minValue={m.min}
                            maxValue={m.max}
                            avgValue={m.avg}
                            value={m.data?.value}
                            //@ts-ignore
                            image={m.sensor.name}
                            remark="Fair"
                            unitValue={m?.data?.value}
                        />)}

                </div>

            </div>
            <div className="savings-section">
                <span className="sub-title">SAVINGS</span>
                <hr className="horizontal-line" style={{marginBottom: "2rem"}}/>

                <div className="row g-5">
                    <GreenSavingsBar title="Electricity" value={75} totalSaved={25.6}/>
                </div>
            </div>

        </SuhsteyView>
    );
};

export default Dashboard;
