import React from 'react';
import {Columns, Bell, GraphUp, Coin} from 'react-bootstrap-icons';
import {FaQuestionCircle} from 'react-icons/fa';
import './Navigation.scss';
import {NavLink} from 'react-router-dom';
import CONSTANTS from '../../helpers/constants';

const Navigation = () => {
    const getIcon = (route: string) => {
        switch (route) {
            case CONSTANTS.APP_ROUTES.DASHBOARD:
                return <Columns/>;
            case CONSTANTS.APP_ROUTES.TIPS:
                return <FaQuestionCircle/>;
            // case CONSTANTS.APP_ROUTES.REWARDS:
            //     return <FaHandshake/>;
            case CONSTANTS.APP_ROUTES.SAVINGS:
                return <Coin/>;
            case CONSTANTS.APP_ROUTES.ALERTS:
                return <Bell/>;
            case CONSTANTS.APP_ROUTES.PERFORMANCE:
                return <GraphUp/>;
            default:
                return <Columns/>;
        }
    }
    return (
        <div className="menu">
            <div className="menu__list">
                {Object.keys(CONSTANTS.APP_ROUTES).map((k: string) => (
                    <NavLink
                        exact={true}
                        // @ts-ignore
                        to={CONSTANTS.APP_ROUTES[k]}
                        className="menu__list__link"
                        activeClassName="menu__list__link-active">
                        <li className="menu__list__link__item">
                            {/*// @ts-ignore*/}
                            {getIcon(CONSTANTS.APP_ROUTES[k])}
                            <span className="menu__list__link__item__text">{k}</span>
                        </li>
                    </NavLink>
                ))}
            </div>
        </div>

    );
};

export default Navigation;
